.circular-chart {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.10)
}

.users-box{
    border-radius: 5px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}
.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge, and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }