.admin-home-page-container{
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.10) 247px, transparent 20%);
    .text-welcome{
        color: #FFF;
        font-family: "Inter";
        // font-size: 40px;
        font-style: normal;
        font-weight: 700;
        // line-height: 20px;
        text-align: left;
    }
    .statistical-wrapper{
        display: flex;
        column-gap: 16px;
        row-gap: 28px;
        flex-wrap: wrap;
    }
    .statistical-card{
        padding: 24px;
        border-radius: 8px;
        background: #060606;
        box-shadow: 0px 10px 13px 0px rgba(17, 38, 146, 0.05);
        height: 100%;
        .text-total{
            color: #FFF;
            font-family: "Inter";
            font-size: 33px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: left;
        }
        .text-bigger-total{
            color: #FFF;
            font-family: "Inter";
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: left;
        }
        .text-hrs{
            color: #16F6FE;
            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .text-title{
            color: #FFF;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .text-show-all{
            color: #FFF;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-decoration: underline;
            cursor: pointer;
        }
        .statistic-by-select{
            border: none;
            .ant-select-selector{
                border: none !important;
                background: rgba(0, 0, 0, 0.3) !important;
                border-radius: 8px;
                .ant-select-selection-search{
                    .ant-select-selection-search-input{
                        height: 100%;
                        color: #FFF !important;
                        font-family: "Inter";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
                .ant-select-selection-placeholder{
                    color: #FFF !important;
                    font-family: "Inter";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
        
                    position: relative;
                    top: -2px;
                }
                .ant-select-selection-item{
                    color: #FFF !important;
                    font-family: 'Inter';
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
        
                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
            }
        }
        :is(.text-percent){
            color: #FFF;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: left;
        }
        :is(.text-description){
            color: #FFF;
            text-align: right;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: left;
        }
        .canvas-chart-container{
            width: 0;
            flex: auto;
            position: relative;
        }
        .text-increase{
            display: flex;
            gap: 4px;
            align-items: center;
            color: #24FF00;
            font-family: "Inter";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .text-decrease{
            display: flex;
            gap: 4px;
            align-items: center;
            color: #FF1F00;
            font-family: "Inter";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}