.music-and-delivery-partner-container{
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    gap: 29px;
    align-items: center;
    z-index: 2;

    :is(.btn-music, .btn-delivery){
        padding: 10px 25px;
        background: rgba($color: #000000, $alpha: 0.5);
        display: flex;
        gap: 11px;
        border-radius: 12px;
        color: #FFF;
        font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        user-select: none;
        cursor: pointer;
    }
}