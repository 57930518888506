.chart-container {
    width: 80vw; /* Default for smaller screens */
    height: 326px; /* Default for smaller screens */
  }
  
  @media (min-width: 768px) { /* Adjust breakpoint as needed */
    .chart-container {
      width: 365px; /* Adjust width for desktop */
      height: 332px; /* Adjust height for desktop */
    }
  }