.transform-collapse-container{
    background: rgba(0, 0, 0, 0.30);
    border-radius: 5px;
    position: relative;
    .ant-collapse-header{
        padding: 9px 7px !important;
        align-items: center;
        .ant-collapse-header-text{
            color: #FFF;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
    .ant-collapse-content{
        .ant-collapse-content-box{
            padding: 17px 10px;
            padding-top: 5px;
            .transform-collapse-content{
                .transform-item{
                    display: grid;
                    grid-template-columns: 55px 1fr 1fr 1fr 34px;
                    gap: 17px;
                    align-items: center;
                }
                .transform-item-end{
                    display: grid;
                    grid-template-columns: 55px 1fr 34px;
                    gap: 17px;
                    align-items: center;
                }
                .header-name{
                    color: #A3A3A3;
                    font-family: "Inter";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    display: flex;
                    
                }
                .title{
                    color: #FFF;
                    font-family: "Inter";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-align: left;
                }
                .transform-input{
                    border-radius: 2px;
                    background: rgba(0, 0, 0, 0.30);
                    height: 24px;

                    color: #FFF;
                    font-family: "Inter";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    border: none;
                    input{
                        color: #FFF;
                        font-family: "Inter";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        border: none;
                        height: 24px;
                    }
                }
                .transform-input-uniform{
                    input{
                        padding: 0 !important;
                        text-align: center;
                    }
                }
                .action{
                    display: flex;
                    justify-content: space-between;
                    .text-reset{
                        font-family: Inter;
                        font-size: 10px;
                        color: #FFF;
                        font-weight: 400;
                        border-bottom: solid 1px #FFE600;
                        cursor: pointer;
                    }
                }
                .uniform-scale-slider{
                    .ant-slider-track{
                        background: linear-gradient(90deg, #00F6FF 2%, #AB00FF 98.2%) !important;
                    }
                }
                .axes-helper-checkbox{
                    .ant-checkbox{
                        width: 20px;
                        height: 20px;
                        .ant-checkbox-inner{
                            width: 14px;
                            height: 14px;
                            top: 2px;
                            left: 2px;
                        }
                    }
                    span{
                        line-height: 12px;
                    }
                    .checkbox-content{
                        color: #FFF;
                        font-family: "Inter";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 12px;
                        text-align: left;
                    }
                }
            }
        }
    }
}