.notification-container{
    position: fixed;
    box-sizing: border-box;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;
    .notification-item{
        width: 580px;
        max-width: 90vw;

        padding: 7px 9px;
        border-radius: 18px;
        border-radius: 30px;
        background: #FFF;
        box-shadow: 0px 7px 10px 0px rgba(255, 255, 255, 0.25);
        
        display: flex;
        align-items: center;
        gap: 12px;

        position: relative;
        // bottom: -200px;
        animation: ease-in-y 0.3s linear;

        .image-product-container{
            width: 88px;
            height: 36px;
            border-radius: 18px;
            overflow: hidden;
            img{
                object-fit: cover;
            }
        }
        .notification-content{
            flex: auto;
            text-align: left;
            .normal-text{
                color: #000;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .bold-text{
                color: #FF6500;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
        .btn-action{
            padding: 9px 23px;
            border-radius: 30px;
            background: #000;

            color: #FFF;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
}

@keyframes ease-in-y {
    0%{
        bottom: -200px;
    }
    100%{
        bottom: 0px;
    }
}

@keyframes destroy-in-3s {
    0%{
        display: none;
    }
    100%{
        display: none;
    }
}