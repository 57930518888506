.drawer-instruction{
    background: transparent !important;
    .ant-drawer-body{
        padding: 0;
        .drawer-instruction-container{
            height: 100%;
            .drawer-title-container{
                padding: 17px 24px;
                background: rgba(0, 0, 0, 0.30);
                backdrop-filter: blur(12.5px);
                display: flex;
                justify-content: space-between;
                .title{
                    color: #FFF;
                    font-family: "Inter";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
                .close-container{
                    padding: 2px 7px;
                    border-radius: 3px;
                    background: rgba(0, 0, 0, 0.50);
                    cursor: pointer;
                    .text-close{
                        color: #FFF;
                        font-family: "Inter";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
            .drawer-content-wrap{
                background: rgba(0, 0, 0, 0.60);
                backdrop-filter: blur(12.5px);
                height: calc(100% - 58px);
                overflow-y: auto;

                /* width */
                &::-webkit-scrollbar {
                    width: 10px;
                }
                
                /* Track */
                &::-webkit-scrollbar-track {
                    background: #000;
                    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.25);
                    border-radius: 30px;
                }
                
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #FFF; 
                    border-radius: 10px;
                }
                
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #FFF; 
                }

                .drawer-content-container{
                    .instruction-group{
                        .instruction-item{
                            padding: 12px 24px;
                            &:nth-child(even){
                                background: rgba($color: #FFF, $alpha: 0.2);
                            }
                            &.instruction-item-header{
                                padding-bottom: 0;
                                .header-text{
                                    font-family: Inter;
                                    font-size: 32px;
                                    font-weight: 600;
                                    line-height: 39px;
                                    letter-spacing: 0em;
                                    text-align: left;
                                    color: #FFF;

                                    margin-top: 36px;
                                    padding-bottom: 12px;
                                    border-bottom: solid 1px #FFF;
                                }
                            }
                            span{
                                font-family: Inter;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 19px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: #FFF;
                            }
                            .instruction-layout{
                                display: grid;
                                grid-template-columns: 1fr 138px;
                                gap: 45px;
                                align-items: center;
                            }
                            kbd{
                                font-family: Inter;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 19px;
                                letter-spacing: 0em;
                                text-align: left;
                                color: #393939;

                                padding: 8px 11.29px;
                                border-radius: 5px;
                                background-color: #FFFFFF;
                                box-shadow: 0px -5px 1.2000000476837158px 0px #00000040 inset, 0px 4px 4px 0px #00000040;

                                min-width: 35px;
                                min-height: 35px;
                                text-align: center;

                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }
    }
}