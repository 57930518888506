.tutorial-page-container{
    .text-trending{
        font-family: Inter;
        font-size: 22px;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: left;
        color: #FFFFFF;
    }
}