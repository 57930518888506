.email-inp:-webkit-autofill,
.email-inp:-webkit-autofill:focus {
  background-color: #181818 !important;
  -webkit-text-fill-color: rgb(248, 250, 252) !important;
  box-shadow: 0 0 0px 1000px #181818 inset !important;
  color: rgb(248, 250, 252) !important;
  border: none !important;
  caret-color: rgb(248, 250, 252);
}

.email-inp:-webkit-autofill::first-line {
  color: rgb(248, 250, 252) !important;
}