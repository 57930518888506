.products-container-header{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  padding: 0 24px;
  .products-container-tittle{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 36px;
      color: var(--normal-text-color);
  }
  .text-add-new{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: var(--normal-text-color);
      cursor: pointer;
  }
}