.object-list-tree-control{
    padding: 12px 20px;
    background: #00F6FF;
    box-shadow: 0px 4px 4px 0px #00000040;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    .checkbox-check-all{
        margin-inline-end: 0;
        .ant-checkbox-inner{
            background: transparent;
            border-radius: 2px;
            border: solid 1px #000;
            &::after{
                border-color: #000;
            }
        }
    }
}
.object-list-search-container{
    height: 48px;
    transition: height 0.3s;
    padding: 0 20px 12px 20px;
    background: #00F6FF;
    box-shadow: 0px 4px 4px 0px #00000040;
    overflow: hidden;
    &.hidden{
        height: 0px;
    }
    .input-search-tree{
        width: 100%;
        border: solid 1px #007B80;
        height: 36px;
        border-radius: 8px;
        outline: none;
        padding: 0 12px;

        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;
    }
}
.object-list-tree-container{
    background: transparent;
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #FFFFFF;

    width: 100%;
    flex: auto;
    overflow-y: auto;
    .ant-tree-list{
        .ant-tree-list-holder{
            .ant-tree-list-holder-inner{
                .ant-tree-treenode{
                    padding-left: 12px;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    padding-right: 12px;
                    width: 100%;
                    align-items: center;
                    &:nth-child(odd){
                        background: #D9D9D903;
                    }
                    &:nth-child(even){
                        background: #D9D9D90D;
                    }
                    &.ant-tree-treenode-selected{
                        background: #a5a5a59f;
                    }
                    &:has(.ant-tree-indent .ant-tree-indent-unit):has(.object-list-first-node){
                        .ant-tree-indent .ant-tree-indent-unit:first-child{
                            display: none;
                        }
                    }
                    &:has(.ant-tree-indent .ant-tree-indent-unit):not(:has(.object-list-first-node)):not(:has(.ant-tree-switcher.ant-tree-switcher_open)):not(:has(.ant-tree-switcher.ant-tree-switcher_close)){
                        .ant-tree-indent .ant-tree-indent-unit:first-child{
                            display: none;
                        }
                    }
                    &:not(:has(.ant-tree-indent .ant-tree-indent-unit)):has(.object-list-first-node){
                        .ant-tree-switcher.ant-tree-switcher-noop{
                            display: none;
                        }
                    }
                    .ant-tree-switcher.ant-tree-switcher_open, .ant-tree-switcher.ant-tree-switcher_close{
                        align-self: center !important;
                        display: none;
                    }
                    .ant-tree-switcher.ant-tree-switcher-noop{
                        margin-left: 11px;
                        &:has(+ .ant-tree-node-content-wrapper){
                            display: none;
                        }
                    }
                    .ant-tree-indent .ant-tree-indent-unit{
                        margin-left: 11px;
                        &::before{
                            top: -8px;
                            bottom: -8px;
                            border-style: dashed;
                        }
                    }
                    .ant-tree-iconEle{
                        width: 20px;
                        height: 20px;
                        margin-left: 15px;
                    }
                    .ant-tree-title{
                        padding-left: 15px;

                        flex: auto;
                    }
                    .ant-tree-switcher-leaf-line{
                        &::before{
                            top: -8px;
                            bottom: -8px;
                            border-style: dashed;
                        }
                        &::after{
                            border-style: dashed;
                        }
                    }
                    .ant-tree-node-selected{
                        background-color: transparent;
                    }
                    .ant-tree-node-content-wrapper{
                        padding-left: 0;
                        padding-right: 0;
                        
                        flex: auto;
                        display: inline-flex;
                        &:hover{
                            background: transparent;
                            .object-list-node-action{
                                opacity: 1;
                            }   
                        }
                        .object-list-node-action{
                            opacity: 0;
                            transition: all 0.2s linear;
                        }
                    }
                    .ant-tree-draggable-icon{
                        display: none;
                    }
                    .ant-tree-indent, .ant-tree-draggable-icon, .ant-tree-node-content-wrapper, .ant-tree-switcher, .ant-tree-switcher-noop{
                        order: 2;
                    }
                    .ant-tree-checkbox{
                        margin-inline-end: 0;
                        margin-left: 9px;
                        align-self: unset;
                        order: 1;
                        .ant-tree-checkbox-inner{
                            background: transparent;
                            border-radius: 2px;
                            border: solid 1px #FFF;
                        }
                    }
                    &.ant-tree-treenode-leaf-last{
                        .ant-tree-switcher-leaf-line{
                            &::before{
                                top: -8px !important;
                                border-style: dashed;
                                height: 18px !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .object-list-icon{
        width: 20px;
        height: 20px;
        svg{
            width: 20px;
            height: 20px;
        }
    }
    .object-list-node-container{
        .object-list-input-name{
            background: transparent;
            border: solid 1px #FFFFFF;
            outline: none;

            font-family: Inter;
            color: #FFFFFF;
        }
    }
}

.dropdown-object-list-filter{
    padding: 4px 12px;
    border-radius: 8px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    gap: 4px;
    .object-list-filter-item{
        padding: 4px 16px;
        cursor: pointer;
        &.active{
            background: #cccccc;
        }
        &:hover{
            background: #cccccc;
        }
    }
}