.dashboard-retailer-drobA-sidebar-wrapper{
    position: sticky !important;
    top: 0;
    background: var(--dashboard-retailer-sidebar-container-background) !important;
    height: 100vh;
    overflow-y: auto;
    border-right: var(--border-dashboard-retailer-sidebar);
    scrollbar-width: none;
    .dashboard-retailer-drobA-sidebar-container{
        height: 100%;
        overflow-y: auto;
    }

    &::-webkit-scrollbar{
        display: none;
    }
    .dashboard-retailer-sidebar-icon{
        height: auto;
        filter: var(--svg-filter-invert);
    }
    &.drob-a-side-layout .ant-layout-sider-children .dashboard-retailer-drobA-sidebar-container{
        display: flex;
        flex-direction: column;
    }
    &.collapsed{
        .avatar-info{
            padding: 5px;
            justify-content: center;
        }
        .dashboard-sidebar-content{
            .ant-menu-item{
                padding-inline: calc(50% - 12px);
            }
        }
        .dashboard-retailer-sidebar-icon{
            width: 16px;
            height: auto;
            max-height: 16px;
        }
    }
    .ant-menu-item{
        text-align: left;
        &.ant-menu-item-disabled{
            cursor: pointer;
        }
    }
    .tutorial-button{
        display: flex;
        gap: 12px;
        align-items: center;
        cursor: pointer;
        background: var(--tutorial-button-background);
        border-radius: 4px;
        padding: 4px;
        img{
            filter: var(--svg-filter-invert);
        }
        .tutorial-div-container{
            text-align: left;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 120%;
            color: var(--normal-text-color);

            .text-title{
                font-weight: 600;
                color: var(--dark-blue-text);
            }
        }
    }
    .tutorial-button-collapse{
        padding: 8px;
        border-radius: 4px;
        background: var(--tutorial-button-collapse-background);
        img{
            filter: var(--svg-filter-invert);
        }
    }
    .text-time-remaining{
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--normal-text-color);
    }
    .avatar-info{
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 5px 17px 5px 21px;
        flex-direction: column;
        .avatar-name{
            color: var(--normal-text-color);
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            flex: auto;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .avatar-action{
            svg{
                filter: var(--svg-filter-invert);
            }
        }
        .plane-name{
            color: #000;
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            padding: 4px 10px;
            border-radius: 12px;
            background-image: url("../../assets/images/layout/plan-bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
        }
        .avatar-edit-profile{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #8E8E8E;
        }
    }
    .dashboard-sidebar-toggle{
        height: 64.5px;
        border-bottom: 1px solid transparent;
        display: flex;
        justify-content: end;
        align-items: center;
        .btn-toggle{
            display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            position: relative;
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
            background-color: transparent;
            outline: 0px;
            border: 0px;
            cursor: pointer;
            user-select: none;
            vertical-align: middle;
            appearance: none;
            text-decoration: none;
            text-align: center;
            flex: 0 0 auto;
            border-radius: 50%;
            overflow: visible;
            // color: rgba(0, 0, 0, 0.54);
            transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            padding: 12px;
            font-size: 1.75rem;
            margin-right: 12px;
            img{
                width: 24px;
                height: 24px;
                filter: var(--svg-filter-invert);
            }
            &:hover{
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }
    .dashboard-sidebar-content{
        background: var(--dashboard-retailer-sidebar-container-background);
        padding-left: 21px;
        padding-right: 17px;
        .ant-menu-item-selected{
            background-color: rgba(0, 0, 0, 0.06);
            color: rgba(0, 0, 0, 0.88);
        }
        .title{
            margin: 0px;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            color: var(--normal-text-color);
            display: block;
        }
        .ant-menu-item{
            margin-top: 4.67px;
            padding-left: 40px;
            margin-inline: 0;
            margin-block: 0;
            height: 34px;
            .ant-menu-title-content{
                margin-inline-start: 0;
            }
            &:is(.ant-menu-item-selected, .ant-menu-item-active){
                border-radius: 10px;
                background: var(--retailer-header-search-background) !important;
                .title{
                    color: var(--retailer-sidebar-menu-active-background);
                }
                .admin-sidebar-icon{
                    filter: brightness(0) invert(82%) sepia(15%) saturate(4685%) hue-rotate(141deg) brightness(103%) contrast(104%);
                }
            }
        }
    }
    .dashboard-sidebar-footer{
        border-top: 1px solid transparent !important;
        background: var(--dashboard-retailer-sidebar-container-background);
        padding-left: 24px;
        padding-right: 24px;
        .ant-menu-item-selected{
            background-color: rgba(0, 0, 0, 0.06);
            color: rgba(0, 0, 0, 0.88);
        }
        .title{
            margin: 0px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #8E8E8E;
            display: block;
        }
        .value{
            margin: 0px;
            font-family: Roboto, Helvetica, Arial, sans-serif;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.43;
            letter-spacing: 0.01071em;
            color: rgba(0, 0, 0, 0.6);
            display: block;
        }
        .ant-menu-item{
            &.ant-menu-item-active{
                border-left: solid 2px #FFFFFF;
                border-radius: 0 8px 8px 0;
                .title{
                    color: var(--normal-text-color);
                }
            }
        }
    }
}
@media screen and (max-width: 767px){
    .dashboard-retailer-drobA-sidebar-wrapper{
        position: absolute !important;
        border-right: none;
        transition: width 0s, height 0.2s, background 0s !important;
        top: 96px;
        height: calc(100% - 96px) !important;
        background: var(--droba-dashboard-wrapper-background) !important;
        backdrop-filter: blur(4px);

        .dashboard-retailer-drobA-sidebar-container{
            background: var(--dashboard-retailer-sidebar-container-background) !important;
            height: clamp(430px, 430px, 100%) !important;
            border-bottom: solid 3px #00F6FF;
        }
        .dashboard-sidebar-content{
            padding-left: 0;
            padding-right: 0;
            .ant-menu-item{
                border-radius: 0 !important;
            }
        }
        .tutorial-button-container{
            padding-left: 0;
            padding-right: 0;
            .tutorial-button{
                padding: 16px 42px;
                background: var(--retailer-header-search-background);
            }
        }
        &.collapsed{
            height: 0% !important;
        }
        .avatar-info{
            display: none;
        }
        .dashboard-sidebar-toggle{
            display: none;
        }
    }
}