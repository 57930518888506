.attachment-uploader{
    height: 100%;
    .ant-upload.ant-upload-select{
        width: 100% !important;
        height: 100% !important;

        background: rgba(0, 0, 0, 0.30)  !important;
        border: none !important;
        border-radius: 8px !important;

        .upload-button{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8.5px;
            padding-top: 13px;
            padding-bottom: 14px;
            .text-uplaod{
                color: #FFF;
                text-align: center;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 84.023%;
            }
        }
    }
    .preview-upload-image{
        padding: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        .file-name{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #FFF;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}