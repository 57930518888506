.header-normal-container{
    background: var(--project-header-container-background);
    height: auto;
    padding: 16px;
    .btn-preview{
        padding: 8px 0;
        border-radius: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--normal-text-color);
        position: relative;
        z-index: 1;
        background: var(--theme-back-ground);
        display: flex;
        gap: 7px;
        align-items: center;
        justify-content: center;
        width: 136px;
        img{
            filter: var(--svg-filter-invert);
        }
        &::after{
            content: "";
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            background: linear-gradient(90deg, #00F6FF 2%, #AB00FF 98.2%);
            position: absolute;
            top: -2px;
            left: -2px;
            z-index: -3;
            border-radius: 8px;
        }
        &::before{
            content: "";
            width: 100%;
            height: 100%;
            background: var(--theme-back-ground);
            position: absolute;
            top: 0;
            left: 0;
            z-index: -2;
            
            border-radius: 8px;
        }
    }
    .btn-publish{
        padding: 8px 0;
        border-radius: 8px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--button-dark-text-color);
        background: var(--button-dark-background);
        position: relative;
        z-index: 1;
        width: 136px;
        &::after{
            content: "";
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            background: var(--button-dark-background);
            position: absolute;
            top: -2px;
            left: -2px;
            z-index: -3;
            border-radius: 8px;
        }
    }
    .infomation-num{
        position: absolute;
        top: 0;
        right: -3px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: var(--normal-text-color);
        background: linear-gradient(139.91deg, #00F6FF 19.49%, #AB00FF 79.89%);

        min-width: 18px;
        min-height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9px;
    }
}