.rate-infomation-wrapper{
    padding: 20px 14px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 1px 25px 0px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    &.selected{
        border: 2px solid #FF6500;
    }
    .rate-logo{
        height: 20px;
        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .rate-time{
        color: #000;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}