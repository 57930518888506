.decorative-hdri-collapse-container{
    background: rgba(0, 0, 0, 0.30);
    border-radius: 5px;
    .ant-collapse-header{
        padding: 9px 7px !important;
        align-items: center !important;
        .ant-collapse-header-text{
            color: #FFF;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            text-align: left;
        }
    }
    .ant-collapse-content{
        .ant-collapse-content-box{
            padding: 17px 10px;
            padding-top: 5px;
            .decorative-hdri-collapse-container-content{
                .edit-decorative-form-select{
                    text-align: left;
                    border-radius: 6px;
                    background: #00000080;
                    .ant-select-selector {
                        border-radius: 6px;
                        color: #FFFFFF !important;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 15px;
                        letter-spacing: 0em;
                        text-align: left;
                        
                        background: none !important;
                        align-items: center;
                        display: flex;
                        border: none !important;
                        height: 36px !important;
                    }

                    .ant-select-selection-item {
                        color: #FFFFFF !important;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 15px;
                        letter-spacing: 0em;
                        text-align: left;
                        display: flex;
                    }

                    .ant-select-selection-placeholder{
                        color: #FFFFFF !important;
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 15px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
                }
                .btn-upload-new-hdri{
                    background: #00000080;
                    border-radius: 6px;
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 15px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #FFF;
                    padding: 6px;
                    width: 100%;
                }
                .hdri-preview{
                    background: #0000004D;
                    border-radius: 5px;
                    padding: 12px 9px;
                }
                .edit-decorative-form-number-input{
                    border-radius: 2px;
                    background: rgba(0, 0, 0, 0.30);
                    height: 24px;

                    color: #FFF;
                    font-family: "Inter";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    border: none;
                    .ant-input-number-input-wrap{
                        input{
                            color: #FFF;
                            font-family: "Inter";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            padding: 4px 2px;
                        }
                    }
                    input{
                        color: #FFF;
                        font-family: "Inter";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        border: none;
                        height: 24px;
                    }
                }
                .edit-decorative-form-slider{
                    .ant-slider-track{
                        background-image: linear-gradient(180deg, #16F6FE -16.56%, #BB55FF 73.77%);
                    }
                    .ant-slider-handle{
                        &::after{
                            box-shadow: -2px -2px 1px 0px #00000040 inset;
                            width: 11px;
                            height: 11px;
                        }
                        &:hover::after{
                            box-shadow: -2px -2px 1px 0px #00000040 inset;
                        }
                    }
                }
            }
        }
    }
}