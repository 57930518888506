.retailer-products-page{
    .retailer-products-page-top-container{
        padding-bottom: 30px;
        border-bottom: solid 1px #878787;
        .import-product-container{
            display: flex;
            gap: 14px;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            padding: 12px 24px;
            border-radius: 5px;
            background: var(--retailer-card-filter-background);
            .text-import{
                color: var(--normal-text-color);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            .btn-connect{
                padding: 6.2px 36px;
                border-radius: 5px;
                border: var(--retailer-button-border-light);
                background: rgba(255, 255, 255, 0.10);
    
                color: var(--normal-text-color);
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }
    .retailer-products-page-container{
        .product-type-title{
            cursor: pointer;
            color: var(--retailer-header-search-placeholder-color);
            text-align: center;
            font-family: Inter;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 26.667px;
            &.active{
                color: var(--normal-text-color)
            }
        }
        .btn-add-new{
            padding: 6.67px 35px;
            border-radius: 5px;
            background: var(--retailer-button-background-light);
            display: flex;
            gap: 8px;
            flex-wrap: nowrap;
            align-items: center;
            span{
                color: #000;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 26.667px;
            }
        }
    }
}