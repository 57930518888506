.theme-tab-container{
    .logo-color-picker{
        width: 35px;
        height: 35px;
        border: none;
        .ant-color-picker-color-block{
            width: 35px;
            height: 35px;
        }
    }
}