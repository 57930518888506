.drob-a-home-page-container{
    height: 100%;
    // background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.10) 247px, transparent 20%);
    background-image: var(--retailer-home-background);
    .text-welcome{
        color: var(--normal-text-color);
        font-family: "Inter";
        // font-size: 40px;
        font-style: normal;
        font-weight: 700;
        // line-height: 48px;
        text-align: left;
    }
    .drob-a-manage-store{
        background-image: url("../../../assets/images/home/drob-a-bg.png");
        background-size: cover;
        background-position: center center;
        border-radius: 8px;
        border: solid 1px #FFFFFF;

        .text-info{
            font-family: Inter;
            // font-size: 24px;
            font-weight: 800;
            // line-height: 30px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #FFF;
        }

        .btn-manage{
            font-family: Inter;
            // font-size: 24px;
            font-weight: 800;
            // line-height: 30px;
            letter-spacing: -0.02em;
            text-align: left;

            height: 50px;

            background: #FFF;
            border-radius: 5px;
            padding: 0 70px 0 24px;
            position: relative;

            .icon-arrow-right{
                position: absolute;
                right: 18px;
                top: 50%;
                transform: translateY(-50%);
                animation: arrow-right-animation 1s linear;
                animation-iteration-count: 9999;
            }
        }
    }
}

@keyframes arrow-right-animation{
    0%{
        right: 18px;
    }
    50%{
        right: 5px;
    }
    100%{
        right: 18px;
    }
}