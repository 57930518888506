.theme-brand-setup-collapse-container{
    background: rgba(0, 0, 0, 0.30);
    border-radius: 5px;
    padding: 0 12px;
    position: relative;
    .ant-collapse-header{
        padding: 9px 0 !important;
        align-items: center !important;
        flex-direction: row-reverse;
        justify-content: space-between;
        .ant-collapse-header-text{
            color: #FFF;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 19.36px;
        }
        .ant-collapse-expand-icon{
            padding-inline-end: 0 !important;
        }
    }
    .ant-collapse-content{
        .ant-collapse-content-box{
            padding: 17px 0;
            padding-top: 5px;
            .theme-brand-setup-collapse-content{
                .input-name{
                    border-radius: 5px;
                    background: rgba(0, 0, 0, 0.30);
                    height: 40px;
                    border: none;
                    color: #FFF;
                    font-family: "Inter";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    &:disabled{
                        color: rgba(255, 255, 255, 0.50);
                    }
                }
                .text-description{
                    color: #FFF;
                    font-family: "Inter";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    border-radius: 5px;
                    background: rgba(0, 0, 0, 0.40);
                    border: none;
                    text-align: justify;
                    padding: 8px;

                    /* width */
                    &::-webkit-scrollbar {
                        width: 10px;
                    }
                    
                    /* Track */
                    &::-webkit-scrollbar-track {
                        background: #000;
                        box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.25);
                        border-radius: 30px;
                    }
                    
                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: #FFF; 
                        border-radius: 10px;
                    }
                    
                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                        background: #FFF; 
                    }
                }
                .total-info{
                    .num{
                        color: #00DCFF;
                        font-family: "Inter";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
                    .total{
                        color: #FFF;
                        font-family: "Inter";
                        font-size: 10px;
                        font-style: normal;
                        font-weight: 200;
                        line-height: normal;
                    }
                }
            }
        }
    }
}