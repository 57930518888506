.decorative-materials-editor-admin{
    background: #0000004D;
    border-radius: 5px;
    .select-material-container{
        border-radius: 5px 5px 0 0;
        background: #171717;
        padding: 12px;
        .material-select{
            text-align: left;
            border-radius: 6px;
            background: #535353;
            .ant-select-selector {
                border-radius: 6px;
                color: #FFFFFF !important;
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
                
                background: none !important;
                align-items: center;
                display: flex;
                border: none !important;
                height: 30px !important;
            }

            .ant-select-selection-item {
                color: #FFFFFF !important;
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
                display: flex;
            }

            .ant-select-selection-placeholder{
                color: #FFFFFF !important;
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
            }
        }
        .text-material{
            font-family: Inter;
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: #FFF;
        }
    }
    .material-control-container{
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 12px;
        .material-select{
            text-align: left;
            border-radius: 6px;
            background: #00000080;
            .ant-select-selector {
                border-radius: 6px;
                color: #FFFFFF !important;
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
                
                background: none !important;
                align-items: center;
                display: flex;
                border: none !important;
                height: 30px !important;
            }

            .ant-select-selection-item {
                color: #FFFFFF !important;
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
                display: flex;
            }

            .ant-select-selection-placeholder{
                color: #FFFFFF !important;
                font-family: Inter;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
            }
        }
        .material-slider{
            .ant-slider-track{
                background-image: linear-gradient(180deg, #16F6FE -16.56%, #BB55FF 73.77%);
            }
            .ant-slider-handle{
                &::after{
                    box-shadow: -2px -2px 1px 0px #00000040 inset;
                    width: 11px;
                    height: 11px;
                }
                &:hover::after{
                    box-shadow: -2px -2px 1px 0px #00000040 inset;
                }
            }
        }
        .material-number-input{
            border-radius: 2px;
            background: rgba(0, 0, 0, 0.30);
            height: 24px;

            color: #FFF;
            font-family: "Inter";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border: none;
            .ant-input-number-input-wrap{
                input{
                    color: #FFF;
                    font-family: "Inter";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    padding: 4px 2px;
                }
            }
            input{
                color: #FFF;
                font-family: "Inter";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                border: none;
                height: 24px;
            }
        }
        .text-control-label{
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #FFFFFF;
        }
        .material-color-picker{
            width: 35px;
            height: 35px;
            padding: 0;
            background: transparent;
            border-radius: 5px;
            .ant-color-picker-color-block{
                width: 35px;
                height: 35px;
                border-radius: 5px;
                .ant-color-picker-color-block-inner{
                    width: 35px;
                    height: 35px;
                }
            }
        }
        .color-text{
            padding: 6px 8px;
            border-radius: 6px;
            background: #00000080;
            font-family: Inter;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #FFFFFF;
        }
    }
}